'use client';

/**
 * LocationTrackerProvider is a context provider that tracks the source of navigation events.
 * It distinguishes between browser-triggered navigations (e.g., using the back/forward buttons)
 * and application-triggered navigations (e.g., using links within the app). This information is
 * stored in the context and can be accessed by other components to determine the source of the
 * current navigation event.
 */
import { PropsWithChildren, createContext, use, useEffect, useState } from 'react';
import { usePathname } from 'next/navigation';

export enum NavigationTrigger {
  BROWSER = 'browser',
  APP = 'app',
}

const LocationTrackerContext = createContext<{
  redirectionSource: string | null;
}>({
  redirectionSource: null,
});

export const useLocationTrackerContext = () => {
  return use(LocationTrackerContext);
};

export const LocationTrackerProvider = ({ children }: PropsWithChildren) => {
  const pathname = usePathname();
  const [redirectionSource, setRedirectionSource] = useState<string | null>(null);

  useEffect(() => {
    const handlePopState = () => {
      setRedirectionSource(NavigationTrigger.BROWSER);
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  useEffect(() => {
    setRedirectionSource(NavigationTrigger.APP);
  }, [pathname]);

  return (
    <LocationTrackerContext.Provider value={{ redirectionSource }}>
      {children}
    </LocationTrackerContext.Provider>
  );
};
