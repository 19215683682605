'use client';

import { PropsWithChildren, useEffect, useState } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { getBaseURL } from '@/app/actions';
import { makeAxiosClient } from '@/legacy/common/axiosApiClient';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export default function LegacyPageWrapper({ children }: PropsWithChildren): JSX.Element {
  const [axiosReady, setAxiosReady] = useState(false);

  useEffect(() => {
    if (globalThis.axiosInitialized) {
      setAxiosReady(true);
      return;
    }
    getBaseURL().then((baseURL) => {
      makeAxiosClient(baseURL);
      setAxiosReady(true);
    });
  }, []);

  if (!axiosReady) {
    return <></>;
  }

  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
}
