'use client';

import { PropsWithChildren, createContext, use, useEffect, useState } from 'react';
import Intercom, { update } from '@intercom/messenger-js-sdk';
import getUserMetadata from '@/lib/getUserMetadata';

type Props = {
  appId: string;
  isIntercomMessengerEnabled: boolean;
};

const IntercomContext = createContext<{ hasInitialized: boolean }>({ hasInitialized: false });

export const useIntercomContext = () => {
  return use(IntercomContext);
};

const API_BASE = 'https://api-iam.intercom.io';

export const IntercomProvider = ({
  appId,
  children,
  isIntercomMessengerEnabled,
}: PropsWithChildren<Props>): JSX.Element => {
  const [hasInitialized, setHasInitialized] = useState(false);

  useEffect(() => {
    if (isIntercomMessengerEnabled) {
      Intercom({
        api_base: API_BASE,
        app_id: appId,
        hide_default_launcher: true,
      });

      setHasInitialized(true);

      // This is done asynchronously so we don't block the Intercom initialization.
      getUserMetadata().then((userSummary) => {
        if (!!userSummary) {
          update({
            email: userSummary.email,
            name: `${userSummary.firstName} ${userSummary.lastName}`,
            user_id: userSummary.id.toString(),
          });
        }
      });
    }
  }, [isIntercomMessengerEnabled, appId]);

  return <IntercomContext.Provider value={{ hasInitialized }}>{children}</IntercomContext.Provider>;
};
