import(/* webpackMode: "eager", webpackExports: ["SnackbarProvider","useSnackbar"] */ "/app/node_modules/@ghosts-inc/design-system/dist/hooks/useSnackbar.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/@mui/material/Button/Button.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/@mui/material/Stack/Stack.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/@mui/material/SvgIcon/SvgIcon.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/@mui/material/Typography/Typography.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/tailwindcss/tailwind.css");
;
import(/* webpackMode: "eager", webpackExports: ["SegmentInit"] */ "/app/src/analytics/SegmentInit.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/ThemeRegistry/ThemeRegistry.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LocationTrackerProvider"] */ "/app/src/context/LocationTrackerProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/datadog/DatadogInit.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["IntercomProvider"] */ "/app/src/helpdesk/IntercomProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DirtyContextProvider"] */ "/app/src/hooks/useBeforeUnload/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ConfirmDialogProvider"] */ "/app/src/legacy/common/confirm/useConfirm.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/legacy/LegacyPageWrapper.tsx");
